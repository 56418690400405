import vuetify, { defaults } from '@/plugins/vuetify';
import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import alert from './modules/alert';
import pendingLoginManagePanel from './modules/pendingLoginManagePanel';
import notification from './modules/notification';
import gw from './modules/gw';
import Cookies from 'js-cookie';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    debug: !!Cookies.get('debug'),
  },
  mutations: {
    setDebug(state, val) {
      state.debug = val;
    },
  },
  actions: {},
  modules: {
    auth,
    alert,
    pendingLoginManagePanel,
    gw,
    notification,
  },
  getters: {
    debug() {
      return /;?\s*debug=1(;|$)/.test(document.cookie);
    },
  },
});

export default store;

store.watch(
  (_, getters) => getters['auth/role'],
  role => {
    vuetify.framework.theme.themes.light.primary =
      {
        admin: '#24936E',
        manager: '#986DB2',
        agent: '#3A84CF',
      }[role] || defaults.primary;

    if (role === 'admin') {
      store.dispatch('gw/initSocket').catch(() => null);
    } else {
      store.dispatch('gw/disconnect').catch(() => null);
    }
  },
);
