var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[_vm._l((_vm.routes),function(ref){
var to = ref.to;
var key = ref.key;
var icon = ref.icon;
var textIcon = ref.textIcon;
var title = ref.title;
var children = ref.children;
var divider = ref.divider;
var inset = ref.inset;
var badge = ref.badge;
var exact = ref.exact;
return [(divider)?_c('v-divider',{attrs:{"inset":inset}}):(children)?_c('v-list-group',{key:key,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-action',[_c('v-badge',_vm._b({staticClass:"full-width",attrs:{"value":!!badge,"overlap":""}},'v-badge',badge,false),[(icon)?_c('v-icon',{staticClass:"iconHoverColor",attrs:{"color":"white"}},[_vm._v(_vm._s(icon))]):(textIcon)?_c('v-icon',{staticClass:"text-icon"},[_vm._v(_vm._s(textIcon))]):_vm._e()],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(_vm._s(title))])],1)]},proxy:true}],null,true)},_vm._l((children),function(child){return _c('v-list-item',{key:child.key,staticClass:"v-list-child",attrs:{"to":child.to,"exact":child.exact,"color":"drawerHover"}},[_c('v-list-item-action',[_c('v-badge',_vm._b({staticClass:"full-width",attrs:{"value":!!child.badge,"overlap":""}},'v-badge',child.badge,false),[(child.icon)?_c('v-icon',[_vm._v(_vm._s(child.icon))]):(child.textIcon)?_c('v-icon',{staticClass:"text-icon"},[_vm._v(_vm._s(child.textIcon))]):_vm._e()],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"AccountChildTitle",domProps:{"textContent":_vm._s(child.title)}})],1)],1)}),1):_c('v-list-item',{key:key,attrs:{"to":to,"exact":exact,"color":"drawerHover"}},[_c('v-list-item-action',[_c('v-badge',_vm._b({staticClass:"full-width",attrs:{"value":!!badge,"overlap":""}},'v-badge',badge,false),[(icon)?_c('v-icon',{staticClass:"iconHoverColor"},[_vm._v(_vm._s(icon))]):(textIcon)?_c('v-icon',{staticClass:"text-icon"}):_vm._e()],1)],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(_vm._s(title))])],1)],1)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }