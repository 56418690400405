<template>
  <div>
    <custom-label class="label" :title="title" :item-field-name="itemFieldName" :text="text" />
    <slot
      :outlined="outlined"
      :dense="dense"
      :readonly="readonly"
      :value="value"
      :persistent-placeholder="persistentPlaceholder"
      :rules="rules"
      :required="required"
      :attrs="$attrs"
      :on="$listeners"
    >
      <component
        :is="tag"
        :outlined="outlined"
        :dense="dense"
        :readonly="readonly"
        :value="value"
        :persistent-placeholder="persistentPlaceholder"
        :rules="rules"
        :required="required"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </slot>
  </div>
</template>

<script>
import customLabel from './customLabel';
import VTextField from 'vuetify/lib/components/VTextField';

export default {
  name: 'LabelWithTextField',
  props: {
    value: {},
    tag: { default: () => VTextField },
    title: String,
    itemFieldName: String,
    text: String,
    dense: { Boolean, default: false },
    rules: {},
    outlined: { Boolean, default: true },
    persistentPlaceholder: Boolean,
    required: { Boolean, default: true },
    readonly: Boolean,
  },
  components: {
    customLabel,
  },
};
</script>
<style>
.inputInfo .label {
  margin-bottom: 0px !important;
  margin-left: 30px;
  min-width: 20%;
}
.inputInfo .label span {
  color: rgb(142, 142, 142);
}

.inputInfo fieldset {
  border: none !important;
  width: 50%;
}

/* .inputInfo .v-input__slot {
  margin-right:50px !important;
} */
.inputInfo .theme--light.v-input input,
.theme--light.v-input textarea {
  color: black !important ;
  font-weight: bold;
}
</style>
