/**
 * @this {Vue}
 */
export function getMenuData() {
  const vm = this;
  const debug = vm.$store.getters.debug;
  const benature = process.env.VUE_APP_BENATURE;
  return benature && !debug
    ? [
        {
          title: 'Chats',
          key: 'chat',
          to: { name: 'Chats' },
          icon: 'mdi-chat',
        },
        {
          title: 'My Profile',
          key: 'myprofile',
          to: { name: 'myprofile' },
          icon: 'mdi-account',
        },
      ]
    : [
        {
          title: 'Dashboard',
          key: 'dashboard',
          to: { name: 'Dashboard' },
          icon: 'mdi-view-dashboard',
        },
        {
          title: 'Chats',
          key: 'chat',
          to: { name: 'Chats' },
          icon: 'mdi-chat',
        },
        {
          title: 'Teams',
          key: 'teams',
          to: { name: 'Teams' },
          icon: 'mdi-account-group',
        },
        {
          title: 'My Team',
          key: 'MyTeam',
          to: { name: 'MyTeam' },
          icon: 'mdi-account-group',
        },
        {
          title: 'Accounts',
          key: 'accounts',
          children: [
            {
              title: 'Admin',
              textIcon: 'A',
              key: 'admin',
              to: `/profiles?role=admin`,
              exact: true,
              auth: ['admin'],
            },
            {
              title: 'Manager',
              textIcon: 'M',
              key: 'manager',
              to: `/profiles?role=manager`,
              exact: true,
            },
            {
              title: 'Agent',
              textIcon: 'A',
              key: 'agent',
              to: `/profiles?role=agent`,
              exact: true,
            },
            {
              title: 'Client',
              textIcon: 'C',
              key: 'client',
              to: `/profiles?role=client`,
              exact: true,
            },
          ],
          icon: 'mdi-account-box-multiple',
        },
        // {
        //   title: 'Accounts',
        //   key: 'profiles',
        //   to: { name: 'profiles' },
        //   icon: 'mdi-account-box-multiple',
        // },
        // {
        //   divider: true,
        //   inset: true,
        // },
        {
          title: 'My Profile',
          key: 'myprofile',
          to: { name: 'myprofile' },
          icon: 'mdi-account',
        },
        // {
        //   divider: true,
        //   inset: true,
        // },
        {
          title: 'System Config',
          key: 'systemConfig',
          to: { name: 'systemConfig' },
          icon: 'mdi-cogs',
          get badge() {
            const hasWarning = vm.$store.getters['gw/hasWarning'];
            return hasWarning
              ? {
                  content: hasWarning,
                  color: 'danger',
                }
              : null;
          },
        },
        {
          title: 'Activities',
          key: 'activityManagement',
          to: { name: 'ActivityManagement' },
          icon: 'mdi-note-search',
        },
/*         {
          title: 'Notification',
          key: 'notification',
          to: { name: 'Notification' },
          icon: 'mdi-bell',
          get badge() {
            const hasWarning = vm.$store.getters['notification/needNotify'];
            return hasWarning
              ? {
                  dot: true,
                  color: 'danger',
                }
              : null;
          },
        }, */
        {
          title: 'Bulk Message',
          key: 'bulkMessage',
          to: { name: 'BulkMessage' },
          icon: 'mdi-comment-text-multiple',
        },
        // {
        //   title: 'Bulk Message Queue',
        //   key: 'bulkMessageQueue',
        //   to: { name: 'bulkMessageQueue' },
        //   icon: 'mdi-chevron-triple-right',
        // },
      ];
}
