import { IClientProfile } from '@/api/auth/membership';
import { GlobalSchemaFields, populatedArrayCtor, populatedCtor } from '@/api/mongooseTypes';
import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {Partial<ITeam>} patch
 */
export async function patchTeam(api, id, patch) {
  return populatedCtor((await api.patch('im/admin/teams/' + id, patch)).data, ITeam);
}

/**
 * @param {AxiosInstance} api
 * @param {{page?: number, offset?: number, limit?: number, search?: string, select?: string[]}} [params]
 *
 */

export async function listTeam(api, params) {
  const { data } = await api.get('im/admin/teams/', { params });
  return {
    ...data,
    docs: populatedArrayCtor(data.docs, ITeam),
  };
}

/* TODO: get the endPoint */
/**
 * @param {AxiosInstance} api
 * @param {{selectedRole?:string,page?: number, offset?: number, limit?: number, search?: string, select?: string[]}} [params]
 * @return {Promise<teamLabels[]>}
 */
export async function getTeamLabel(api, params) {
  return await api.get('im/admin/teams/labels', { params });
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {{page?: number, offset?: number, limit?: number, search?: string, select?: string[]}} [params]
 */
export async function readTeam(api, id, params) {
  const { data } = await api.get('im/admin/teams/' + id, { params });
  return populatedCtor(data, ITeam);
}

/**
 * @param {AxiosInstance} api
 * @param {*} patch
 */
export async function patchMyTeam(api, patch) {
  return (await api.patch('im/team/myTeam', patch)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{select?: string[]}} [params]
 */
export async function getMyTeam(api, params) {
  const { data } = await api.get('im/team/myTeam', { params });
  return populatedCtor(data, ITeam);
}

/**
 * @param {AxiosInstance} api
 */
export async function listIdleChats(api) {
  const { data } = await api.get('im/chat/idleChats');
  return data;
}

export class ITeam extends GlobalSchemaFields {
  /** @type {string} */
  label;
  /** @type {boolean} */
  blocked;
  /** @type {{start?: Date, end?: Date, timezone?: number}} */
  workTimePeriod;
  /** @type {boolean} */
  needApproval;
  /** @type {string[]} */
  activityLabels = [];
  /** @type {string[]} */
  clientTags = [];
  /** @type {IClientProfile[]} */
  _senderIdentities;
  /** @type {string} */
  ChatGPTSystemContent;
  ChatGPTChatConfig;

  constructor(obj) {
    super(obj);
    obj._senderIdentities = populatedCtor(obj._senderIdentities, IClientProfile);
    obj.workTimePeriod = obj.workTimePeriod || {};
    if (obj.workTimePeriod.timezone == null) {
      obj.workTimePeriod.timezone = -new Date().getTimezoneOffset();
    }
    obj.activityLabels = obj.activityLabels || [];
    obj.clientTags = obj.clientTags || [];
    Object.assign(this, obj);
  }
}
