import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import defaultLayout from '@/layout/default';
import LoginPage from '@/views/LoginPage';
import authLayout from '@/layout/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: defaultLayout,
    redirect: { name: 'Chats' },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "manager" */ '../views/Dashboard/'),
        meta: {
          auth: ['admin', 'manager'],
        },
      },
      {
        path: '/notification',
        name: 'Notification',
        component: () => import(/* webpackChunkName: "manager" */ '../views/Notification/'),
        meta: {
          auth: ['admin', 'manager', 'agent'],
        },
      },
      {
        path: '/bulkMessage',
        name: 'BulkMessage',
        component: () => import('../views/BulkMessage/'),
        meta: {
          auth: ['manager'],
        },
      },
      // {
      //   path: '/bulkMessageQueue',
      //   name: 'bulkMessageQueue',
      //   component: () => import('../views/BulkMessageQueue/'),
      //   meta: {
      //     auth: ['manager'],
      //   },
      // },
      {
        path: '/activities',
        name: 'ActivityManagement',
        component: () => import(/* webpackChunkName: "manager" */ '../views/ActivityManagement/'),
        meta: {
          auth: ['admin', 'manager'],
        },
      },
      {
        path: '/activities/:id([0-9a-zA-Z]{24})',
        name: 'Activity',
        component: () =>
          import(/* webpackChunkName: "manager" */ '../views/ActivityManagement/activityDetails'),
        meta: {
          auth: ['admin', 'manager'],
        },
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      },
      {
        path: '/teams',
        name: 'Teams',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Teams/'),
        meta: {
          auth: ['admin'],
        },
      },
      {
        path: '/teams/:id([0-9a-zA-Z]{24})',
        name: 'Team',
        component: () => import(/* webpackChunkName: "admin" */ '../views/Teams/teamDetails'),
        meta: {
          auth: ['admin'],
        },
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      },
      {
        path: '/myteam',
        name: 'MyTeam',
        component: () => import(/* webpackChunkName: "agent" */ '../views/Teams/myTeam'),
        meta: {
          auth: ['manager'],
        },
      },
      {
        path: '/profiles',
        name: 'Profiles',
        component: () => import(/* webpackChunkName: "manager" */ '../views/Identities'),
        meta: {
          auth: ['admin', 'manager'],
        },
        props: r => ({ selectedRole: r.query.role }),
      },
      {
        path: '/profiles/:id([0-9a-zA-Z]{24})',
        name: 'Profile',
        component: () =>
          import(/* webpackChunkName: "manager" */ '../views/Identities/identityDetails'),
        meta: {
          auth: ['admin', 'manager'],
        },
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      },
      {
        path: '/changeUsername/:id([0-9a-zA-Z]{24})',
        name: 'ChangeUsername',
        component: () => 
          import(/* webpackChunkName: "manager" */ '../views/Identities/changeUserName'),
        meta: {
          auth: ['admin'],
        },
        props: r => ({ id: r.params.id})
      },
      {
        path: '/loginAccounts',
        name: 'LoginAccounts',
        component: () => import(/* webpackChunkName: "manager" */ '../views/LoginAccounts/'),
        meta: {
          auth: ['admin', 'manager'],
        },
      },

      {
        path: '/loginAccounts/:id([0-9a-zA-Z]{24})',
        name: 'LoginAccount',
        component: () =>
          import(/* webpackChunkName: "admin" */ '../views/LoginAccounts/loginAccountDetails'),
        meta: {
          auth: ['admin'],
        },
        props: r => ({ id: r.params.id, editMode: !!r.query.edit }),
      },
      {
        path: '/chats',
        name: 'Chats',
        component: () => import(/* webpackChunkName: "agent" */ '../views/ChatPage.vue'),
        meta: {
          auth: ['admin', 'manager', 'agent'],
        },
      },
      {
        path: '/chats/:id',
        name: 'Chat',
        props: r => ({ selectedChatId: r.params.id }),
        component: () => import(/* webpackChunkName: "agent" */ '../views/ChatPage.vue'),
        meta: {
          auth: ['admin', 'manager', 'agent'],
        },
      },

      {
        path: '/systemconfig',
        name: 'systemConfig',
        component: () => import(/* webpackChunkName: "admin" */ '../views/SystemConfigPage.vue'),
        meta: {
          auth: ['admin', 'manager'],
        },
      },
      {
        path: '/myprofile',
        name: 'myprofile',
        component: () => import(/* webpackChunkName: "agent" */ '../views/MyProfile.vue'),
        meta: {
          auth: ['admin', 'manager', 'agent'],
        },
      },
    ],
  },
  {
    path: '/auth',
    name: 'authHome',
    component: authLayout,
    redirect: { name: 'login' },
    children: [
      {
        path: '/auth/login',
        name: 'login',
        component: LoginPage,
        props: r => ({ ret: r.query.ret }),
      },
      {
        path: '/auth/forgetpassword',
        name: 'forgetpassword',
        component: () => import('../views/LoginPage/forgetpw.vue'),
      },
      {
        path: '/forgotpassword',
        name: 'Password',
        component: () => import('../views/LoginPage/resetpw.vue'),
        props: r => ({ token: r.query.token }),
      },
    ],
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  if (to.meta && to.meta.auth) {
    if (!store.getters['auth/isLoggedIn']) {
      return next({ name: 'login', query: { ret: to.fullPath } });
    }
    if (Array.isArray(to.meta.auth)) {
      if (!to.meta.auth.includes(store.getters['auth/role'])) {
        return next('/');
      }
    }
  }
  next();
});

export function canAccessRoute(to) {
  const r = router.resolve(to)?.route;
  if (!r) return false;
  return r && (!Array.isArray(r.meta?.auth) || r.meta.auth.includes(store.getters['auth/role']));
}
