<template>
 
    <v-list>
      <template
        v-for="{ to, key, icon, textIcon, title, children, divider, inset, badge, exact } in routes"
      >
        <v-divider v-if="divider" :inset="inset" />
        <v-list-group v-else-if="children" :key="key">
          <template v-slot:activator>
            <v-list-item-action>
              <v-badge :value="!!badge" overlap v-bind="badge" class="full-width">
                <v-icon class="iconHoverColor" color="white" v-if="icon">{{ icon }}</v-icon>
                <v-icon v-else-if="textIcon" class="text-icon">{{ textIcon }}</v-icon>
              </v-badge>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="title">{{ title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="child in children"
            :key="child.key"
            :to="child.to"
            :exact="child.exact"
            color="drawerHover"
            class="v-list-child"
          >
            <v-list-item-action>
              <v-badge :value="!!child.badge" overlap v-bind="child.badge" class="full-width">
                <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                <v-icon v-else-if="child.textIcon" class="text-icon">{{ child.textIcon }}</v-icon>
              </v-badge>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="AccountChildTitle" v-text="child.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else :key="key" :to="to" :exact="exact" color="drawerHover">
          <v-list-item-action>
            <v-badge :value="!!badge" overlap v-bind="badge" class="full-width">
              <v-icon class="iconHoverColor" v-if="icon">{{ icon }}</v-icon>
              <v-icon v-else-if="textIcon" class="text-icon"></v-icon>
            </v-badge>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="title">{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

</template>

<script>
import { canAccessRoute } from '@/router';
import { getMenuData } from '@/services/menu';
import { mapGetters } from 'vuex';

export default {
  name: 'drawerNav',
  computed: {
    ...mapGetters('auth', ['role']),
    menu: getMenuData,
    routes() {
      const role = this.role;
      const filter = arr =>
        arr
          .map(x => {
            if (x.children) {
              x = { ...x, children: filter(x.children) };
              if (x.children.length) return x;
            } else if (x.to != null) {
              return canAccessRoute(x.to) && (!x.auth || x.auth.includes(role)) && x;
            } else {
              return x;
            }
          })
          .filter(x => x);
      return filter(this.menu);
    },
  },
};
</script>

<style scoped lang="scss">
.v-list-child {
  padding-left: 40px;
  transition: padding-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.v-navigation-drawer--mini-variant {
  .v-list-child {
    padding-left: 16px;
  }
}

// .title {
//   font-weight: bold;
// }
// .title:active{
//   color:blue;
// }

.title,
.AccountChildTitle {
  color: white;
}

.text-icon {
  font-style: inherit;
  font-size: inherit;
  text-align: center;
  width: 100%;
  color: white;
}
</style>

<style lang="scss">
.v-badge .v-icon {
  color: white;
}

i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: white;
}
.v-list-item--active i.v-icon.notranslate.mdi.mdi-chevron-down.theme--light {
  color: var(---v-drawerHover-base) !important;
}

.v-list-item--active {
  .v-list-item__title,
  .iconHoverColor {
    color: var(---v-drawerHover-base) !important;
  }
}

#app .v-list .v-list-item--active {
  color: var(--v-drawerHover-base);
}

</style>
