import { IChat } from '@/api/chat';
import { GlobalSchemaFields, populatedArrayCtor, populatedCtor } from '@/api/mongooseTypes';
import { AxiosInstance } from 'axios';

/**
 * @param {AxiosInstance} api
 * @param {string} profileId
 * @param {*} patch
 */
export async function patchProfile(api, profileId, patch) {
  return (await api.patch('im/admin/profiles/' + profileId, patch)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {Partial<IProfile & IUser>} profileAndUserFields
 */
export async function createUser(api, profileAndUserFields) {
  return (await api.post('im/admin/profiles', profileAndUserFields)).data;
}

/**
 * @param {AxiosInstance} api
 * @param params
 */
export async function listProfiles(api, params) {
  const { data } = await api.get('im/admin/profiles', { params });
  return {
    ...data,
    docs: populatedArrayCtor(data.docs, IProfile),
  };
}

/**
 * @param {AxiosInstance} api
 * @param id
 * @param params
 */
export async function readProfile(api, id, params) {
  const { data } = await api.get('im/admin/profiles/' + id, { params });
  return populatedCtor(data, IProfile);
}

/**
 * @param {AxiosInstance} api
 * @param {Partial<IProfile & IUser>} profileAndUserFields
 */
export async function createClientProfile(api, profileAndUserFields) {
  return (await api.post('im/admin/profile/createProfile', profileAndUserFields)).data;
}

export class IUser extends GlobalSchemaFields {
  /** @type {String} */
  username;
  /** @type {String} */
  pw;
  /** @type {String} */
  email;
  /**
   * @type {boolean}
   * @readonly
   */
  hasPw;
}

export class IProfile extends GlobalSchemaFields {
  /** @type {string} */
  role;
  /** @type {string} */
  name;
  /** @type {string} */
  name_last;
  /** @type {string} */
  name_first;
  /** @type {string} */
  phone;

  constructor(obj) {
    super(obj);
    const clazz =
      {
        manager: IManagerProfile,
        agent: IAgentProfile,
        client: IClientProfile,
      }[obj.role] || IProfile;
    if (!(this instanceof clazz)) {
      return new clazz(obj);
    }
    Object.assign(this, obj);
  }
}

export class IClientProfile extends IProfile {
  /** @type {boolean} */
  isSender;

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }
}

export class IAgentProfile extends IProfile {
  /** @type {boolean} */
  blocked;
  /** @type {string} */
  team;
  /** @type {IChat[]} */
  assignedChats = [];
  /** @type {{start?: Date, end?: Date, timezone?: number}} */
  workTimePeriod;

  constructor(obj) {
    super(obj);
    obj.workTimePeriod = obj.workTimePeriod || {};
    if (obj.workTimePeriod.timezone == null) {
      obj.workTimePeriod.timezone = -new Date().getTimezoneOffset();
    }
    obj.assignedChats = populatedArrayCtor(obj.assignedChats || [], IChat);
    Object.assign(this, obj);
  }
}

export class IManagerProfile extends IProfile {
  /** @type {boolean} */
  blocked;
  /** @type {string} */
  team;
  /** @type {IClientProfile[]} */
  assignedSender = [];
  /** @type {{start?: Date, end?: Date, timezone?: number}} */
  workTimePeriod;

  constructor(obj) {
    super(obj);
    obj.workTimePeriod = obj.workTimePeriod || {};
    if (obj.workTimePeriod.timezone == null) {
      obj.workTimePeriod.timezone = -new Date().getTimezoneOffset();
    }
    obj.assignedSender = populatedArrayCtor(obj.assignedSender || [], IClientProfile);
    Object.assign(this, obj);
  }
}
